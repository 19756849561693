// React component that will display an image that when clicked opens a GodotGame modal
import React, { useState } from "react";
import GodotGame from "../GameComponent";
import {
  GameDescription,
  GameImage,
  GameTitle,
  GameText,
  GameComponentLink,
} from "../HeroSection/HeroSectioneElements";

export const RATIO_16_9 = 0.5625;
export const RATIO_4_3 = 0.75;

const GameDisplayer = ({ gameUrl, title, text, imageUrl, aspectRatio }) => {
  const [isGameOpen, setIsGameOpen] = useState(false);

  const handleImageClick = () => {
    setIsGameOpen(true);
  };

  const handleBack = () => {
    setIsGameOpen(false);
  };

  return (
    <div>
      <GameComponentLink onClick={handleImageClick}>
        <GameDescription>
          <GameTitle>{title}</GameTitle>
          <GameText>{text}</GameText>
        </GameDescription>
        <GameImage src={imageUrl} alt={title} />
      </GameComponentLink>
      {isGameOpen && (
        <GodotGame
          gameUrl={gameUrl}
          title={title}
          onBack={handleBack}
          aspectRatio={aspectRatio}
        />
      )}
    </div>
  );
};

export default GameDisplayer;
