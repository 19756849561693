import SimonsSecretImage from "../images/simonsSecret.png";
import RadicalRacersX1Image from "../images/radicalRacersX1.png";
import RadicalRacersImage from "../images/radicalRacers.png";
import MegaManSecretImage from "../images/megaManSecret.png";
import SquardianaImage from "../images/squardiana.png";
import FatsoBatImage from "../images/fatsoBat.png";
import TireballImage from "../images/tireball.png";

import { RATIO_16_9, RATIO_4_3, RATIO_9_16 } from "./ratios";

export const gamesData = [
  {
    gameUrl: "https://simons-secret-build.onrender.com/castlevaniaSecret.html",
    title: "Simon's Secret",
    text: "Castlevania tribute game (prototype)",
    imageUrl: SimonsSecretImage,
    aspectRatio: RATIO_4_3,
  },
  {
    gameUrl: "https://radical-racers-x-1.onrender.com/radicalRacersX1.html",
    title: "Radical Racers vX.1",
    text: "Race like it's Windows 98 SE",
    imageUrl: RadicalRacersX1Image,
    aspectRatio: RATIO_16_9,
  },
  {
    gameUrl: "https://radical-racers-x.onrender.com/radicalRacersX.html",
    title: "Radical Racers",
    text: "Race like it's Windows 98",
    imageUrl: RadicalRacersImage,
    aspectRatio: RATIO_4_3,
  },
  {
    gameUrl: "https://megaguy-build.onrender.com/megaGuy.html",
    title: "Mega Man Secret",
    text: "Mega Man tribute game",
    imageUrl: MegaManSecretImage,
    aspectRatio: RATIO_4_3,
  },
  {
    gameUrl: "https://protiana-build.onrender.com/protiana.html",
    title: "Squardiana Jones",
    text: "Swing around in the darkness",
    imageUrl: SquardianaImage,
    aspectRatio: RATIO_4_3,
  },
  {
    gameUrl: "https://fatsobat-build.onrender.com/index.html",
    title: "Fatso Bat",
    text: "Jump slowly in the darkness",
    imageUrl: FatsoBatImage,
    aspectRatio: RATIO_9_16,
  },
  {
    gameUrl: "https://tireball-build.onrender.com/index.html",
    title: "Tireball",
    text: "Watch score increase over a black background",
    imageUrl: TireballImage,
    aspectRatio: RATIO_16_9,
  },
];
