import React from "react";

import { HeroContainer } from "./HeroSectioneElements";

import GameDisplayer from "../GameDisplayer";

import BackgroundImage from "../../images/background.jpg";

import { gamesData } from "../../constants/games";

const HeroSection = () => {
  const style = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
  };
  return (
    <HeroContainer style={style}>
      {gamesData.map((game, index) => (
        <GameDisplayer
          key={index}
          gameUrl={game.gameUrl}
          title={game.title}
          text={game.text}
          imageUrl={game.imageUrl}
          aspectRatio={game.aspectRatio}
        />
      ))}
    </HeroContainer>
  );
};

export default HeroSection;
