import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";

const GodotGame = ({
  gameUrl,
  title,
  width = "80vw", // Use viewport width for responsive design
  aspectRatio = 0.5625, // 16:9 aspect ratio by default
  onBack,
}) => {
  const calculateHeight = (width, aspectRatio) => {
    const calculatedHeight = `calc(${width} * ${aspectRatio} + 80px)`;
    return `min(${calculatedHeight}, 80vh)`;
  };

  const calculateMaxWidth = (height, aspectRatio) => {
    return `calc(${height} / ${aspectRatio})`;
  };

  const [modalIsOpen, setModalIsOpen] = useState(true);
  // const [isMuted, setIsMuted] = useState(false);
  const [height, setHeight] = useState(calculateHeight(width, aspectRatio));
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setHeight(calculateHeight(width, aspectRatio));
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial height

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, aspectRatio]);

  const closeModal = () => {
    setModalIsOpen(false);
    onBack();
  };

  const enterFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) {
        /* Firefox */
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        /* IE/Edge */
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  // const toggleMute = () => {
  //   if (iframeRef.current) {
  //     const iframeWindow = iframeRef.current.contentWindow;
  //     if (iframeWindow) {
  //       iframeWindow.postMessage({ type: "toggleMute" }, "*");
  //       setIsMuted(!isMuted);
  //     }
  //   }
  // };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: width,
      maxWidth: calculateMaxWidth(height, aspectRatio),
      height: height,
      padding: 0, // Remove padding to avoid overflow
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Game Modal"
      style={customStyles}
    >
      <div
        className="flex flex-col items-center p-4"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        }}
      >
        <h1 className="text-2xl font-bold mb-4">{title}</h1>
        <div className="w-full" style={{ height: "calc(100% - 80px)" }}>
          <iframe
            ref={iframeRef}
            src={gameUrl}
            title={title}
            className="w-full h-full"
            style={{ height: "100%", width: "100%" }}
            frameBorder="0"
            allowFullScreen
            isMuted
          />
        </div>
        <div className="w-full max-w-4xl mb-4 flex justify-space-between">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Back to Games
          </button>
          <button
            onClick={enterFullscreen}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 margin-left-auto"
          >
            Fullscreen
          </button>
          {/* <button
            onClick={toggleMute}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            {isMuted ? "Unmute" : "Mute"}
          </button> */}
        </div>
      </div>
    </Modal>
  );
};

export default GodotGame;
