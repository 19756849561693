import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import _ from "lodash";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavItem,
  NavLinks,
  NavMenu,
} from "./NavbarElements";

import SpacedebrixLogo from "../../images/spacedebrixLogo.jpg";

const defaultNav = {
  spy: true,
  smooth: true,
  offset: -80,
  exact: "true",
  duration: 500,
};

const navItems = [
  {
    linkText: "Radical Racers",
    to: "radical-racers",
    ...defaultNav,
  },
  {
    linkText: "Mega Man Secret",
    to: "mega-man-secret",
    ...defaultNav,
  },
  {
    linkText: "Squardiana",
    to: "squardiana",
    ...defaultNav,
  },
  {
    linkText: "Fatso Bat",
    to: "fatso-bat",
    ...defaultNav,
  },
  {
    linkText: "Fatso Bat Beta",
    to: "fatso-bat-beta",
    ...defaultNav,
  },
  {
    linkText: "Tireball",
    to: "tireball",
    ...defaultNav,
  },
];

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            spacedebrix
          </NavLogo>
          <NavMenu>
            {_.map(navItems, (item) => {
              return (
                <NavItem {...item}>
                  <NavLinks {...item}>{item.linkText}</NavLinks>
                </NavItem>
              );
            })}
          </NavMenu>
          <img src={SpacedebrixLogo} />
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
